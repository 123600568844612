.leftPanel {
  --site-min-width: 1000px;
  display: flex;
  flex: auto;
  max-width: 100%;
  /* this line is mainly for Register view, where width can be limited */
  min-width: var(--site-min-width);
}
.leftPanel__leftPanel {
  width: 25%;
  max-width: 280px;
  min-width: calc(var(--site-min-width) / 4);
  position: fixed;
  z-index: 1000;
  min-height: 100%;
  top: 0;
  padding: 1rem;
  background: #ffffff;
}

.leftPanel__navLink {
  display: block;
}

.leftPanel__panelSpacer {
  width: 25%;
  flex-basis: 25%;
  max-width: 280px;
  min-width: calc(var(--site-min-width) / 4);
}
.leftPanel__children {
  padding: 1rem;
  flex: auto;
  display: flex;
  flex-direction: column;
  background: #f9f9f9;
  width: 75%;
  flex-basis: 75%;
}
